import React, { CSSProperties, useMemo } from 'react'
import { Routes, Route, Outlet, useLocation, Location } from 'react-router-dom'
import { ModalUnstyled } from '@mui/base'
import { ViewWrapper } from './components/ViewWrapper'
import { DialogProvider } from './dialogs'
import {
  StateProvider,
  createStore,
  asyncFlow,
} from '@the-platform-company/appbuilder-react-state'
import { RobotenDnDProvider } from '@the-platform-company/react-drag-drop'
import { useConnectionSecrets } from '@the-platform-company/appbuilder-react-connector'
const CallbackView = React.lazy(
  () => import(/* webpackChunkName: "views/callback" */ './views/Callback')
)
const SignInView = React.lazy(
  () => import(/* webpackChunkName: "views/sign-in" */ './views/SignIn')
)
const SignUpView = React.lazy(
  () => import(/* webpackChunkName: "views/sign-up" */ './views/SignUp')
)
const HomeView = React.lazy(
  () => import(/* webpackChunkName: "views/home" */ './views/Home')
)
const LoginFailedView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/login-failed" */ './views/LoginFailed')
)
const Auth0AuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/Auth0AuthCallback" */ './components/connectors/Auth0AuthCallback'
    )
)
const store = createStore()

const modalStyles: CSSProperties = {
  position: 'fixed',
  zIndex: '1300',
  inset: '0px',
}
const App: React.FC = () => {
  const location = useLocation()

  const state = (location.state as { backgroundLocation?: Location }) ?? {}

  const connections = useConnectionSecrets({ connectionNames: ['auth0'] })
  const assets = useMemo(
    () => ({
      dashboarddarkPng: '/assets/dashboarddark-png/dashboarddark.png',
      iconPng: '/assets/icon-png/icon.png',
      faviconPng: '/assets/favicon-png/favicon.png',
      logoPng: '/assets/logo-png/Logo.png',
    }),
    []
  )
  return (
    <>
      <StateProvider
        additionalState={{
          assets,
          connections,
        }}
        store={store}
      >
        <RobotenDnDProvider>
          <DialogProvider>
            <>
              <React.Suspense fallback={null}>
                <Routes location={state?.backgroundLocation ?? location}>
                  <Route
                    path="/"
                    element={
                      <ViewWrapper
                        namespace={'17a6f329-c316-448b-ae19-8d940688582b'}
                      >
                        <HomeView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/callback"
                    element={
                      <ViewWrapper
                        namespace={'d6a9e45b-2b12-42fe-b492-a8ea305c7aa9'}
                      >
                        <CallbackView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/sign-in"
                    element={
                      <ViewWrapper
                        namespace={'06311a3a-ccc2-43ed-b1e8-491b3cbbc1cb'}
                      >
                        <SignInView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/sign-up"
                    element={
                      <ViewWrapper
                        namespace={'31d99155-55c9-48ca-80ad-beeff742e3fa'}
                      >
                        <SignUpView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/login-failed"
                    element={
                      <ViewWrapper
                        namespace={'990f8da5-9786-4426-9513-2345cffc4751'}
                      >
                        <LoginFailedView />
                      </ViewWrapper>
                    }
                  />

                  <Route
                    path="/auth/auth0/callback"
                    element={<Auth0AuthCallback />}
                  />
                </Routes>

                {/* Show the modal when a `backgroundLocation` is set */}
                {state?.backgroundLocation && (
                  <Routes>
                    <Route
                      path=""
                      element={
                        <ModalUnstyled
                          open={!!state?.backgroundLocation}
                          style={modalStyles}
                        >
                          <div>
                            <Outlet />
                          </div>
                        </ModalUnstyled>
                      }
                    >
                      <Route
                        path="/"
                        element={
                          <ViewWrapper
                            namespace={'17a6f329-c316-448b-ae19-8d940688582b'}
                          >
                            <HomeView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/callback"
                        element={
                          <ViewWrapper
                            namespace={'d6a9e45b-2b12-42fe-b492-a8ea305c7aa9'}
                          >
                            <CallbackView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/sign-in"
                        element={
                          <ViewWrapper
                            namespace={'06311a3a-ccc2-43ed-b1e8-491b3cbbc1cb'}
                          >
                            <SignInView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/sign-up"
                        element={
                          <ViewWrapper
                            namespace={'31d99155-55c9-48ca-80ad-beeff742e3fa'}
                          >
                            <SignUpView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/login-failed"
                        element={
                          <ViewWrapper
                            namespace={'990f8da5-9786-4426-9513-2345cffc4751'}
                          >
                            <LoginFailedView />
                          </ViewWrapper>
                        }
                      />
                    </Route>
                  </Routes>
                )}
              </React.Suspense>
            </>
          </DialogProvider>
        </RobotenDnDProvider>
      </StateProvider>
    </>
  )
}
export default App
